<template>
  <div>
    <heads :more="showMore" :msg="title_msg"></heads>
    <div class="all">
      <van-list
        v-show="showDatas"
        v-model="loading"
        :finished="finished"
        finished-text="没有更多小组了"
        @load="loadMore"
      >
        <div v-for="(item, index) in list" :key="index" class="main" @click="go_url(item.detail_url)">
          <div class="main_left">
            <img class="xiaozu_img" :src="item.avatar_url" alt>
          </div>
          <div class="main_center">
            <p class="title">{{ item.old_group_name }}</p>
            <p class="follows">{{ item.attend_num }}人关注</p>
            <p class="introduce">{{ item.introduce }}</p>
          </div>
        </div>
      </van-list>
      <div v-show="!showDatas" class="nodatas">
        <img src="@/static/images/my_index/kong.png" alt="kong">
        <p>您还没有关注的小组~</p>
      </div>
    </div>
  </div>
</template>

<script>
import heads from '@/compontens/heads.vue'
import { get_gzxz_list } from '@/api/my_user.js'

export default {
  components: {
    heads
  },
  data() {
    return {
      title_msg: '关注小组',
      list: [],
      isshow: false,
      showMore: true,
      showDatas: false,
      loading: false,
      finished: false,
      page: 1,
      limit: 10,
      count: 0
    }
  },
  created() {
    this.get_list()
  },
  methods: {
    go_url(urls) {
      window.location.href = urls
    },
    clicks() {
      if (this.isshow === false) {
        this.isshow = true
      } else {
        this.isshow = false
      }
    },
    get_list() {
      const query = {
        page: this.page,
        limit: this.limit
      }
      get_gzxz_list(query).then((res) => {
        if (res.errNo === 0) {
          const result = res.result ?? {}
          const list = result.list || []
          this.count = result.count ?? 0
          this.list = [...this.list, ...list]
          // 是否有数据
          if (this.list.length > 0) {
            this.showDatas = true
            // 是否有更多
            if (this.list.length < this.count) {
              this.page++
              this.finished = false
            } else {
              this.finished = true
            }
          } else {
            this.showDatas = false
          }
        }
      })
    },
    // 加载更多
    loadMore() {
      this.get_list()
    }
  },
  beforeRouteEnter(to, from, next) {
    window.document.body.style.backgroundColor = '#f7f7f7'
    next()
  },
  beforeRouteLeave(to, from, next) {
    window.document.body.style.backgroundColor = ''
    next()
  }
}
</script>

<style lang="scss" scoped>
.nodatas {
  margin-top: 250px;
  text-align: center;
  p {
    color: #333;
    font-size: 26px;
    margin-top: 20px;
  }
}
.img_h {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 30px;
  top: 44px;
  z-index: 1;
}

.main {
  width: 687px;
  height: 172px;
  background: #fff;
  margin: 22px auto;
  border-radius: 12px;
  overflow: hidden;
}
.main_left {
  width: 178px;
  float: left;
  height: 100%;
}
.main_center {
  position: relative;
  width: 375px;
  height: 100%;
  // background: navy;
  float: left;
}
.xiaozu_img {
  width: 115px;
  height: 115px;
  margin: 38px 0 0 20px;
  border-radius: 15px;
}
.introduce {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #333;
  font-size: 24px;
  position: absolute;
  bottom: 30px;
  width: 330px;
}
.main_right {
  margin-top: 63px;
  // width: 170px;
  height: 100%;
}
.follow_btn {
  width: 92px;
  height: 44px;
  border: 2px solid #ddd;
  background: #fff;
  border-radius: 10px;
  color: #999999;
  line-height: 44px;
}
.title {
  color: #333;
  font-size: 30px;
  padding-top: 32px;
  font-weight: 600;
}
.follows {
  color: #999;
  font-size: 24px;
  position: absolute;
  bottom: 66px;
}
</style>
